.searchBox {
    display: flex;
    align-items: center;
    gap: 15px;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 40px;
    padding: 0 10px;
}

.input {
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
}

.iconBox {
    display: flex;
}
