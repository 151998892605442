.editChannel {
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    height: 100%;
    padding-bottom: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 5%;
}

.avatarBox {
    padding: 10px 0 0 0;
}

.description {
    width: 100%;
    padding: 5px;
    border-radius: 8px;
    border: none;
    border: 1px solid rgb(182, 180, 180);
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #404d66;
    resize: none;
    outline: none;
}

.btnSave {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
}
.sliderBox {
    padding: 0 10px;
}
