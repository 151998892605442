.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.rowItemBox {
  /* margin: 10px 0;
    background-color: bisque; */
}

.loadingBox {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: auto;
  justify-content: center;
}
