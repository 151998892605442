.container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
}

.contentBox {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    height: 90%;
    overflow: auto;
}

.contentTop {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 35%;
    align-items: center;
    justify-content: center;
}

.contentBottom {
    height: 65%;
}

.description {
    width: 100%;
    padding: 5px;
    border-radius: 8px;
    border: none;
    border: 1px solid #b6b4b4;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #404d66;
    resize: none;
    outline: none;
}

.settings {
    padding: 15px 0;
}

.buttonSubmit {
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    /* height: 60px; */
    padding: 5px 15px 20px;
    background-color: #fff;
    box-shadow: 1px -5px 10px 2px rgba(67, 67, 67, 0.1);
}

::-webkit-scrollbar {
    display: none;
}

.colorUser {
    width: 25px;
    height: 25px;
    border: none;
    padding: 0;
}

.sliderBox {
    padding: 0 5px;
}
