.settings {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.imageBox {
    width: 100%;
    max-height: 50%;
    object-fit: contain;
}

.footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
