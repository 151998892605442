.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 5px auto;
    padding: 5px 10px;
    transition: 0.15s ease-in-out;
    cursor: pointer !important;
}
.container:hover {
    background-color: #ddd;
}
.groupInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.headerTitle {
    font-size: 10px;
    font-weight: bolder;
    padding: 0;
    margin: 0 0 0 10px;
}

.card {
    border-radius: 5px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.3s ease-in-out;
}

.rightbarBox {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

.card:hover {
    cursor: pointer;
}

.text {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
}

.relevanceBox {
    display: flex;
    align-items: center;
    gap: 5px;
}
