.headerBox {
    display: flex;
    width: 100vw;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.login_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.socialContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin-top: 50px;
}
.forgotBox {
    text-align: end;
    margin: 0 0 20px 0;
    cursor: pointer;
}
