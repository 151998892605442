.inputmessage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  z-index: 15;
}
.iconClick {
  padding: 8px;
  border-radius: 50%;
}

.iconClick:hover {
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(134, 134, 134, 0.473);
}
.voiceIcon {
  display: flex;
}
