.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.formItemBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.formBox {
    display: flex;
    height: 100%;
    width: 250px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loginInputButton {
    border-radius: 20px !important;
    width: 240px;
    height: 40px !important;
    margin: 0 auto;
    margin-top: 1rem;
    font-size: 18px;
}
