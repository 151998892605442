.settings {
    display: flex;
    flex-direction: column;
    padding: 20px;
}
.colorUser {
    width: 25px;
    height: 25px;
    border: none;
    padding: 0;
}
.footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}
