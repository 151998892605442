.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #404d66;
    background-color: blue;
}

.main {
    flex: 1;
    height: 75%;
    overflow-y: auto;
}

.contentBox {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0 auto;
    width: 90%;
}

.loadingBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SearchBox {
    width: 90%;
    margin: 10px auto;
}

.channelRowBox {
    position: relative;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    border-radius: 10px;
    width: 310px;
    padding: 8px;
    background-color: #7fa88b99;
}

.channelNameBox {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.channelLocation {
    display: flex;
    gap: 5px;
    align-items: center;
}
.premiumIcon {
    position: absolute;
    top: 5px;
    right: 5px;
}
.notifIcon {
    position: absolute;
    top: -10px;
    right: 25px;
    width: 25px;
    padding: 0 5px;
    height: 25px;
    background-color: red;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
