*,
*::before,
*::after {
    box-sizing: border-box;
}

.textFieldContainer {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-bottom: 20px;
    position: relative;
}

.validationInput {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    color: #333;
    background-color: transparent;
    outline: none;
    border: none;
    transition: all 0.3s ease-in-out;
}

.validationInput:focus {
    border-color: #4299e1;
}

.isInValid {
    border-color: #fc8181;
}

.error {
    position: absolute;
    color: #fc8181;
    font-size: 10px;
    height: 25px !important;
    width: 100%;
    display: block;
    /* bottom: 0; */
    top: 100%;
}

.label {
    font-size: 14px;
}
.passwordInput {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    border: 1px solid rgba(67, 71, 77, 0.09);
    border-radius: 10px;
}
.showHide {
    position: absolute;
    right: 5px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    padding: 3px;
    border-radius: 50%;
    display: block;
}
.showHide:hover {
    background-color: #ddd;
    transition: 0.2s ease-in-out;
}

.iconBox {
    padding: 10px 0px 10px 20px;
}
