.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.contentBox {
    display: flex;
    width: 100%;
    padding: 0 7%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.formBox {
    padding: 10px 7%;
}

.formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
}

.formItem input {
    border: none;
    color: #747474;
    width: 50%;
    font-size: 14px;
    outline: none;
}
