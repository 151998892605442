.container {
    width: 100%;
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
}

.ownCard {
    display: flex;
    gap: 10px;
    /* flex-direction: column; */
    width: 250px;
    padding: 10px 10px 0px 10px;
    border-radius: 20px;
    align-items: center;
}

.listDoc {
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 10px;
}

.fileInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
}

.card {
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 20px;
    border-radius: 20px;
    align-items: center;
}

.ondownloadCard {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    box-sizing: border-box;
}
.docMessage {
    border-radius: 20px;
    background-color: #fff;
}
