.container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.buttonBox {
  display: flex;
  width: 100%;
}

.full {
  display: flex;
}

.rounded {
  width: 100%;
  border-radius: 25px;
  padding: 3px 10px;
  border: 0;
  cursor: pointer;
  color: #fff;
  font-family: "Montserrat";
  font-weight: 600;
  transition: 0.2s ease;
  white-space: nowrap;
}

.notRounded {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 25px;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-family: "Montserrat";
  font-family: 700;
  transition: 0.2s ease;
  white-space: nowrap;
}

.rounded:hover {
  opacity: 0.8;
}
.notRounded:hover {
  opacity: 0.8;
}

.uppercase {
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.notuppercase {
  padding: 0;
  margin: 0;
}

@media (max-width: 1100px) {
  .rounded {
    font-size: 14px;
  }
  .notRounded {
    font-size: 14px;
  }
}
