.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.contentBox {
  width: 400px;
  height: 100%;
}

.language {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  align-items: center;
}

.radio {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 10px;
}

.languagesName {
  padding: 20px;
  font-size: 18px;
}
