.container {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    overflow: hidden;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.imageBox {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px;
}
