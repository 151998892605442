.parentContainer {
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}
.childContainer {
  width: fit-content;
  z-index: 10;
}

.avatarCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.textCard {
  width: 100%;
  max-width: 550px;
  padding: 10px 15px;
  padding-bottom: 5px;
  align-items: center;
  border-radius: 15px;
  z-index: 1;
}
.linkPreviewInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
