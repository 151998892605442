.added {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: red !important;
}

.add {
    background-color: rgba(127, 168, 139, 0.6) !important;
}

.searchBox {
    padding: 10px 7%;
}

.container {
    background-color: #fff;
    width: 100%;
    height: 100v;
    overflow-y: scroll;
}
