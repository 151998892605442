.header {
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
}
.headerItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    transition: ease-in-out 0.1s;
    padding: 3px 0;
}
.headerItem:nth-child(1),
.headerItem:nth-child(2),
.headerItem:nth-child(3) {
    border-right: 1px solid #ddd;
}

.headerItem:hover {
    background-color: #eee;
    cursor: pointer;
}
.headerItem:active {
    background-color: #ddd;
    cursor: pointer;
}
.wrap {
    overflow: scroll;
    height: 88vh;
    padding-bottom: 20px;
}
.listVideo {
    display: flex;
    width: 100%;
    height: auto;
    padding: 15px 5px;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1px;
    align-items: flex-start;
}
.listVideoItem {
    display: flex;
    box-shadow: 1px 1px 5px 1px rgba(1, 1, 1, 0.2);
    /* border-radius: 5px; */
    overflow: hidden;
    width: 32%;
    height: 110px;
    align-items: center;
    cursor: pointer;
    transition: ease-in-out 0.15s;
}

.listDocItem {
    display: flex;
    box-shadow: 1px 1px 5px 1px rgba(1, 1, 1, 0.2);
    /* border-radius: 5px; */
    overflow: hidden;
    width: 100%;
    align-items: center;
    cursor: pointer;
    transition: ease-in-out 0.15s;
}
.image {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.image img {
    width: 100%;
    height: 110px;
    object-fit: cover;
}
.listVideoItem:hover {
    transform: scale(1.05);
}
/* audio */
.audioList {
    width: 100%;
    display: flex;
    padding: 8px;
    flex-direction: column;
    gap: 8px;
    overflow-y: scroll;
}

.audioItem {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 1px rgba(1, 1, 1, 0.2);
}
