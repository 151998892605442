.Register {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Motserrat6";
}

.registerBox {
    display: flex;
    width: 100%;
    height: 70vh;
    margin: auto 0;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    /* background-color: beige; */
}

.registerBoxTop {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
}

.registerBoxBottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: aqua; */
}

.registerBoxTop > h1 {
    display: flex;
    font-size: 45px;
    padding: 5px;
    text-align: center;
}

.chatIcon > img {
    width: 100px;
    height: 50px;
    object-fit: cover;
}

.loginBtn {
    display: flex;
    width: 190px;
    height: 45px;
    font-size: 18px;
    border: none;
    color: #fff;
    background: #0084ff;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s ease;
    font-family: "Montserrat";
    font-weight: 700;
}

.loginBtn:hover {
    background: blue;
    color: #fff;
}

.createAnAccount {
    color: #0084ff;
    font-size: 18px;
    font-family: "Montserrat";
    font-weight: 700;
    padding: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: 0.2s ease;
}

.createAnAccount:hover {
    color: blue;
}

.languageBox {
    cursor: pointer;
}
