.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.main {
    flex: 1;
    /* background-color: yellow; */
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.searchBox {
    width: 90%;
    margin: 15px auto;
}

.loadingBox {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.acceptReject {
    display: flex;
    align-items: center;
    gap: 5px;
}
