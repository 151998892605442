.added {
    cursor: not-allowed;
    opacity: 0.7;
}

.add {
    background-color: rgba(127, 168, 139, 0.6) !important;
}

.searchBox {
    padding: 5px 10px;
}
