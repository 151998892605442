.container {
    width: 100%;
    height: 100%;
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.avatarBox {
    display: flex;
    width: 100%;
    padding: 10px 7%;
}

.groupsBox {
    margin-top: 10px;
}

.contentBox {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 150px;
}

.ImageInputBox {
    display: flex;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(95, 92, 92);
    background-color: rgb(160, 157, 157);
    cursor: pointer;
    align-items: center;
}

.getChannelsBox {
    height: 2px;
    width: 90%;
    margin: 10px auto;
    background-color: rgb(201, 201, 201);
}

.loadingBox {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.judgementText {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 5px 7%;
}

.judgementText textarea {
    width: 100%;
    height: 110px;
    border: 1px solid #eaecf0;
    background-color: #fff;
    border-radius: 10px;
    resize: none;
    padding: 6px;
    font-weight: 100;
}

.formBox {
    padding: 10px 7%;
}

.formItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0;
}

.formItem input {
    border: none;
    border-bottom: 1px solid #999;
    color: #747474;
    width: 50%;
    font-size: 14px;
    outline: none;
    padding: 2px 5px;
}
.formItem input:focus {
    border-bottom: 2px solid dodgerblue;
}

.btnBox {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 7% 15px 7%;
    gap: 5px;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 1px -10px 15px 0px rgba(1, 1, 1, 0.1);
}
.formItem .colorUser {
    width: 25px;
    height: 25px;
    border: none;
    padding: 0;
}
.nameWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
