.text {
  font-size: 16px;
  font-family: "Montserrat";
}

.centerText {
  width: 100%;
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
}
