@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
}

body {
    background-color: #fff;
    padding: 0;
    font-family: 'Montserrat';
    font-family: 600;
    color: #404d66;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    padding: 0;
    margin: 0;
}

::-webkit-scrollbar {
    width: 4px;
}

::-webkit-scrollbar-thumb {
    background-color: #b8b4b4;
    border-radius: 20px;
}

.unblock_user_btn {
    white-space: nowrap;
    background-color: crimson !important;
}

::placeholder {
    color: rgba(67, 71, 77, 0.2);
    opacity: 1;
}

.ant-btn-default {
    background: crimson;
    color: #fff;
    border: none;
}

.ant-btn-primary {
    background-color: rgb(127, 168, 139);
}
