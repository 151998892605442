.container {
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 5px;
    width: 100%;
    min-height: 75px;
    border-left: 1px solid #eee;
    transition: 0.2s ease-in-out;
    z-index: 15;
}

.reply_message_box {
    padding: 0;
}

.replyMessage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding-left: 5px;
}

.userName {
    font-size: 14px;
}
.replyIcon {
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
}

.replyMessageType {
    font-size: 14px;
    font-weight: 500;
    font-family: 'Montserrat';
    color: rgb(204, 204, 204);
}

.replyIcon::before {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    width: 2px;
    height: 100%;
    background-color: #1e74f5;
}

.replyTextCard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
    padding-left: 20px;
}

.replyVideoCard {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    align-items: center;
    padding-left: 20px;
}

.reply_video_message_box {
    position: relative;
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cencelBtn {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
