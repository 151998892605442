
.hashtag {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5 10px;
}
.hashtaginput {
  width: 80%;
  height: 45px;
  border: none;
  outline: none;
  font-size: 15px;
  color: #333;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 50px;
  padding: 0 15px;
  font-family: Montserrat;
  font-weight: 700;
}
.hashtagIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 20%;
  height: 100%;
}
