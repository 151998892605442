.footerToolbar {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 45px;
    z-index: 15;
}
.toolbarLeft {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    gap: 10px;
}

.toolbarRight {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    gap: 20px;
}
.labelForCss {
    width: 25px;
    height: 25px;
    background-color: red;
}
