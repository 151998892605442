.header {
    padding: 15px;
}
.upload {
    display: flex;
    margin-bottom: 10px;
}
.description {
    width: 100%;
    padding: 5px;
    border-radius: 8px;
    border: none;
    border: 1px solid #b6b4b4;
    font-family: 'Montserrat';
    font-weight: 500;
    color: #404d66;
    resize: none;
    outline: none;
    font-size: 14px;
}

.uploadLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5px;

    p {
        cursor: pointer;
    }
}

.footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
}

.fileUrl {
    padding: 10px;
}
