.container {
    width: 100px;
    height: 90px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.nameBox {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%);
}
.channelName {
    font-size: 20px;
    padding: 0 5px;
    overflow: hidden;
    text-align: center;
    font-family: "Montserrat";
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
}
.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
