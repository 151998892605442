.container {
    /* background-color: rgb(227, 227, 227); */
    border-radius: 50%;
    display: flex;
    /* padding: 8px; */
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
/* .container:hover {
  background-color: rgb(218, 218, 218);
  cursor: pointer;
} */
