.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin: 8px 0;
    padding: 6px 0;
}

.right {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 15px;
}

.iconBox {
    min-width: 25px;
}

.rightIconSection {
    position: relative;
}

.deleteIcon {
    position: absolute;
    left: 10px;
    cursor: pointer;
    color: red;
}

.cursor-not-allowed {
    cursor: not-allowed;
}
