.owner {
    cursor: not-allowed;
    opacity: 0.7;
    background-color: red !important;
}
.moderator {
    opacity: 0.7;
    background-color: red !important;
}

.new {
    background-color: rgba(127, 168, 139, 0.6) !important;
}

.icons {
    display: flex;
    align-items: center;
    gap: 8px;
}
