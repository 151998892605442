.container {
    width: 100%;
    height: 180px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    text-align: center;
}
.avatarBox {
    width: 100%;
    height: 100%;
}
.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    cursor: pointer;
}
.uploadIcon {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%);
    /* z-index: 2; */
    cursor: pointer;
}

.avatarInput {
    visibility: hidden;
}
