.iconsHoverAndActive:hover {
  transition: ease-in-out 0.15s;
  background-color: rgba(95, 95, 95, 0.202) !important;
}

.iconsHoverAndActive:active {
  transition: ease-in-out 0.15s;
  background-color: rgba(68, 68, 68, 0.322) !important;
}
.buttonHoverAndActive:hover {
  transition: ease-in-out 0.15s;
  opacity: 0.8;
}
.buttonHoverAndActive:active {
  transition: ease-in-out 0.15s;
  opacity: 1;
}
.inputComonent:focus {
  border-bottom: 2px solid #444 !important;
}
.inputComonent::placeholder {
  color: #999;
}
.react-contexify__item:not(.react-contexify__item--disabled):hover
  > .react-contexify__item__content {
  background-color: red;
}
