.CreateAnAccount {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat";
    font-weight: 600;
}

.CreateAnAccountBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.socialContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
}
