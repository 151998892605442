.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    box-sizing: border-box;
    /* background-color: rgba(255, 255, 255, 0.596); */
    background-color: #fff;
    outline: 1px solid rgba(128, 128, 128, 0.351);
}

.itemsRow {
    padding: 10px 7%;
}
.items {
    padding: 10px 7%;
}

.channalUsers {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    margin-top: 30px;
    overflow-y: scroll;
    padding: 5px 0px;
    max-height: 85vh;
    border-top: 5px solid rgba(144, 144, 144, 0.267);
    background-color: #fff;
}

.description {
    border: 1px solid #eaecf0;
    border-radius: 10px;
    width: 86%;
    height: auto;
    min-height: 100px;
    margin: 10px auto;
    padding: 5px;
    overflow: auto;
    white-space: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.bottomBox {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}
.qrCodeBox {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.webSite {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-size: 14px;
}
