.container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contentBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 300px;
    height: 100%;
    margin-top: 20px;
}
