.container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.content {
    padding: 10px 15px;
}

.bottomBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 20px;
    left: 5%;
    right: 5%;
}
